import { helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
import { templateHttp } from "@/http/template";
import { QuillEditor } from "@vueup/vue-quill";
import { TemplateCategory } from "@/enums";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import { toast } from "@/template/app";
export default defineComponent({
    computed: {
        TemplateCategory() {
            return TemplateCategory;
        }
    },
    components: {
        BaseBadge,
        QuillEditor,
        TeleportToContentFooter,
        BaseFormGroup,
        BaseFormApp,
        BaseFileInput,
    },
    mixins: [helperMixin, saveAndUpdate(templateHttp())],
    data() {
        return {
            variables: []
        };
    },
    methods: {
        async onMounted() {
            await this.fetchVariables();
        },
        async fetchVariables() {
            const { response, status } = await templateHttp().getVariables();
            if (status === 200) {
                this.variables = response.data;
            }
            else {
                toast("error", "Unable to get template variables.", 9);
            }
        },
        addVariable(val) {
            var _a;
            const quill = (_a = this.$refs.quillEditor) === null || _a === void 0 ? void 0 : _a.getQuill();
            if (!quill)
                return;
            const cursorPosition = quill.getSelection(true);
            quill.insertText(cursorPosition, ` {${val}} `, 'italic', true);
            quill.format('italic', false);
        },
        setForm() {
            var _a, _b, _c, _d, _e, _f;
            this.form.subject = (_a = this.data) === null || _a === void 0 ? void 0 : _a.subject;
            this.form.email_to = (_b = this.data) === null || _b === void 0 ? void 0 : _b.email_to;
            this.form.description = (_c = this.data) === null || _c === void 0 ? void 0 : _c.description;
            this.form.is_active = (_d = this.data) === null || _d === void 0 ? void 0 : _d.is_active;
            this.form.category = (_f = (_e = this.data) === null || _e === void 0 ? void 0 : _e.category) !== null && _f !== void 0 ? _f : TemplateCategory.RegisterApproval;
        },
    },
});
